import { conditionsText } from './conditionsText';

const fi = {
indextitle: 'Meri-Teijo Bike Park - Hissipyöräily, Maastopyöräily, Pyörävuokraamo, Teijo, Teijo National Park',
  home: {
  title: 'Meri-Teijo Bike Park',
  campaign: 'Parkkikausi alkaa: Soft Opening lauantait 18.5. & 25.5.',
  month: 'Lokakuussa',
  info: {
    info: 'Kausi käynnissä! 14/14 Rataa on auki.',
    link: 'Tästä kausikortin varaukseen ->',
    mtb: '5 kpl Sähköfätbikejä löytyy vuokraamosta! Varaukset verkkokaupan kautta.',
    mtb2: '',
    shop: 'Tästä verkkokauppaan ->'
  }, 
  week: 'Marraskuussa',
  week2: 'Viikko 25 - Suljettu',
  wed: '',
  thu: 'MTBP + Ansa & Kuoppa Freeride yhteistyökampanja on käynnissä!',
  fri: 'Kauden 2025 Kausikortti on nyt 199€! Ota yhteys sähköpostitse asiakaspalvelu@meriteijobikepark.com',
  sat: 'Kiitos kaikille 5:n kauden puolesta! Sen kunniaksi valmistellaankin jotain aivan huippua ensi vuodeksi. Stay tuned!',
  sun: '',
    aboutus: {
    title: 'Tarinamme',
    lowertitle: 'Meri-Teijo Bike Park',
    text: `Harrastuksesta syttyneen intohimon ansiosta syntyi MTBP. Jaamme tämän luomuksen kanssanne, sekä kutsumme mukaan kasvavaan, kehittyvään ympäristöön ja yhteisöön. Olemme mukana suomalaisen maasto- ja alamäkipyöräilyn nosteessa luomassa lajin ympärille yhteisöä, jossa kaveria ei jätetä.\n \n  Aloittelijoita opastamme ammattitaidolla ja kokeneita haastamme mieleenpainuvilla elementeillä. MTBP on harrastajilta, harrastajille.`
    },
    services: {
    title: 'Bike Park- ja maastopyöräilykeskus Kansallispuiston kainalossa',
      hissipyoraily: {
      title: 'Bike Park',
      text: 'Keskuksemme on erikoistunut alamäkiajoon. Ankkurihissillä ylös ja omaan tahtiin alas. Parkissa on 15 uniikkia rakennettua reittiä, sekä harjoitusalue mattohissillä. Siis ajettavaa kaikentasoisille! Tervetuloa yhteisöömme.',
      button: 'Lue lisää',
      readMoreTitle: 'Bike Park',
      readMore: 'Idea alamäkiajossa on sama kuin talvella lasketellessa: Hissillä ylös ja oma-aloitteisesti alas! Parkista löytyy aloittelijaystävällisiä Flow -ratoja, teknisiä -ratoja, sekä haastavampia ja vauhdikkaita ratoja niitä kaipaaville. Helpoilla Flow -radoilla on turvallisia hyppyreitä, puulaitureita ja muita hauskoja elementejä, joista voi ajaa omaan tahtiin ylitse, eikä vauhtia vaadita. Näillä radoilla ei myöskään ole pahoja kivikkoja tai juurakkoja. Näin kokematonkin pyöräilijä pääsee kokeilemaan lajia turvallisesti. Mattohissirinteestä löytyy myös harjoitusalue, jossa erittäin loiva rata harjoittelua varten. Vuokraamosta löytyy kaikki tarpeellinen varustus kokeilemista varten.\n\n',
      shoplink: 'Kurkkaa vuokrapyöriämme! ',
      trailmap: 'Kurkkaa reittikarttaamme! '
      },
      yrityksille: {
      title: 'Yrityksille & Ryhmille',
      text: 'Suunnittelit sitten alamäkiajoja, sähköfatbikeillä luontoretkeä, opettaa anoppiasi ajamaan maastopyörällä, tai vain pitää hauskaa tai polttareita, niin räätälöimme aktiviteetit juuri sinulle sopivaksi!',
      button: 'Lue lisää',
      readMoreTitle: 'Yrityksille & Ryhmille',
      readMore: 'Valmennuksia tehdään räätälöidysti kaikentasoisille maasto- ja alamäkiajon perusteista hyppyvalmennuksiin. Jos etsit Tyky -päiville, polttareihin, tai muuten vain porukallesi aktiviteettiä, järjestämme juuri sinulle räätälöidyn paketin. Vuokraamosta löytyy kaikki tarpeellinen varustus hissipyöräilyyn, tai maastopoluille. 2023 Kaudella vuokraamossa myös 8 kpl uunituoreita Tunturin eMax sähköfatbikejä. Sähköfatbiket ovat erinomainen tapa tutustua Teijon kansallispuiston upeisiin kohteisiin ja ruukkikyliin. Kyselyt ensisijaisesti sähköpostitse. asiakaspalvelu@meriteijobikepark.com \n\n',
      shoplink: 'Kurkkaa vuokrapyöriämme! ',
      trailmap: 'Kurkkaa reittikarttaamme! '
      },
      maastopyoraily: {
      title: 'Sähkö- ja Maastopyöräily',
      text: 'Kansallispuistossa upeita haastavia polkuja harrastajille, tai helppoja maisemareittejä aloittelijoille. Vuokraamon sähköfatbikeillä retki onnistuu varmasti.',
      button: 'Lue lisää',
      readMoreTitle: 'Sähkö- ja Maastopyöräily',
      readMore: 'Sijaitsemme aivan Teijon kansallispuiston helmassa, Teijo Ski Resortin tiloissa. Maasto ja luonto on voimakkaasti vaihtelevaa tyypiltään ja korkeuseroltaan. Kallioita, kangasmetsää, lehtometsää, järviä, soita, meri, saaristo, sekä historialliset ruukkikylät takaa kauniin miljöön, josta pääsee nauttimaan myös maastopyörän kanssa. Reitistöä alueella on kymmeniä kilometrejä, vaihdellen helpoista neulaspoluista haastaviin kivikkoihin ja juurakoihin, jyrkkiin teknisiin osuuksiin. Alueella on runsaasti moottoriliikenteeltä suljettuja metsäteitä, joita pitkin pääsee etenemään helposti kokematonkin maastopyörän kuljettaja. Vuokraamolta voit vuokrata mm. sähköfatbikejä, joilla voi tutustua alueeseen mielekkäästi. Matildanjärveä ja Jeturkastin pirunpellon maisemaa pääsevät ihailemaan myös liikuntaesteiset. \n\n',
      shoplink: 'Kurkkaa vuokrapyöriämme! ',
      trailmap: 'Kurkkaa reittikarttaamme! '
      },
      vuokraamo: {
      title: 'Vuokraamo & Palvelut',
      text: 'Maasto- tai alamäkipyörät, sähköfatbiket, suojavarusteet, opaspalvelut tai valmennukset. Varaudu retkeen tai bike park päivään kunnon vermeillä, niin varmistamme onnistuneen reissun!',
      button: 'Lue lisää',
      readMoreTitle: 'Vuokraamo & Palvelut',
      readMore: 'Vuokraamosta löytyy täysverisiä alamäkipyöriä, maastopyöriä, sähköfatbikejä ja suojavarusteita. Ammattitaitoinen henkilökunta auttaa sinut suoraan lajin pariin. Verkkokaupasta voit etukäteen käydä suunnittelemassa vierailuasi. Voit varata varusteita tai valmennuksia etukäteen sähköpostitse asiakaspalvelu@meriteijobikepark.com ILMOITA vuokraustarpeissa henkilöiden määrä, pituus ja vuokra-ajan pituus. Jos tahdot valmennuksia tai ajo-opetusta, ole myös suoraan yhteydessä meihin. Parkissa toimii ammattitaitoisia lajin harrastajia ja FMBIA-sertifioituja valmentajia. \n\n',
      shoplink: 'Kurkkaa vuokrapyöriämme! ',
      trailmap: 'Kurkkaa reittikarttaamme! '
      }
    },

    footer: {
    home: 'Etusivu',
    shop: 'Kauppa',
    contact: 'Yhteystiedot',
    followus: 'SOME KANAVAT',
    cart: 'Ostoskori',
    copyright: '© Meri-Teijo Bike Park 2023 | Kuvat © Tomi Tähti / Jere Satamo / Miika Niskanen'        
    }
  },
   
  shop: {
  navbartitle: 'Kauppa',
  title: 'Tervetuloa verkkokauppaan!',
  productTitle: 'Verkkokauppa',
  description: 'Voit ostaa hissilippusi etukäteen verkkokaupasta. Fyysisen lipukkeesi saat meiltä ostotositetta vastaan aukioloaikoina. Kausikortit ovat henkilökohtaisia. Kausikorteilla tai päivälipuilla ei ole palautusoikeutta.',
  announcer: 'Liikuntaeduilla maksaessasi tutustu ensin ohjeisiin.',
    addItem: {
    title: 'Tuote lisätty onnistuneesti ostoskoriin',
    total: 'Yhteensä:',
    continueShoppingButton: 'Jatka Ostoksia',
    toBasketButton: 'Siirry ostoskoriin'
    },
    rentalPopUp: {
    title: 'Välinevuokraamo',
    amount: 'Valitse kuinka monta tarvitset',
    bike: 'Pyörä',
    size: 'Pyörän koko',
    backButton: 'Takaisin',
    nextButton: 'Seuraava',
    total: 'Hinta Yht.',
    },
    rentalUserInfo: {
    title: 'Vuokraajan tiedot',
    gender: 'Sukupuoli',
    skill: 'Taitotaso',
    height: 'Pituus',
    weight: 'Paino',
    info: 'Lisätietoja'
    },
    products: {
    title: 'Tuotteet',
      Tickets: {
      title: 'Hissiliput',
      price: 'Hinta',
      button: 'Lisää koriin',
      notForSale: 'Ei saatavilla',
        Kampanja: {
        title: 'Kausikorttikampanja 2023',
        description: 'Varmista paikkasi kauden tohinoihin edullisesti!'
        },
        Kausikortti: {
        title: 'Kausikortti 2023',
        description: 'Kausikortilla ajat koko kauden. Käytössä SnowCard järjestelmä.'
        },
        Twodays: {
        title: 'Kahden päivän lippu',
        description: 'Voit ostaa itsellesi tai lahjaksi! Käytössä SnowCard järjestelmä.',
        },
        Paiva: {
        title: 'Päivälippu',
        description: 'Voit ostaa itsellesi tai lahjaksi! Käytössä SnowCard järjestelmä.'
        },
        Twohour: {
        title: '2h Lippu',
        description: 'Haluatko vain kokeilla parkkiamme? Voit ostaa itsellesi tai lahjaksi! Käytössä SnowCard järjestelmä.'
        },
        Junior: {
        title: 'Juniorin Päivälippu',
        description: 'Alle 12-v Päivälippu. Käytössä SnowCard järjestelmä.',
        },
      },
      Rentals: {
      title: 'Vuokraamo',
      price: 'Hinta Alk.',
      button: 'Tee Varaus',
      notForSale: 'Ei saatavilla',
      reserve: 'Varaa sähköpostilla',
        Kona134275: {
        title: 'Kona Process 134 27,5"',
        description: 'Täysjousitettu 134mm joustolla varustettu pyörä kaikenlaiseen ajoon. Hyvä pyörä aloittelijoille.'
        },
        Kona13429: {
        title: 'Kona Process 134 29"',
        description: 'Täysjousitettu 134mm joustolla varustettu pyörä kaikenlaiseen ajoon. Hyvä pyörä aloittelijoille.'
        },
        Kona15327: {
        title: 'Kona & Suojavarusteet',
        description: 'Alamäki- tai enduropyörä + koko suojavarustesetti pakettihintaan.'
        },
        KonaHonzo: {
        title: 'Kona Honzo 24"',
        description: 'Etujousitettu Junnujen maasturi. Tällä junnukin pääsee kokeilemaan. Sopii 115-145cm pitkille.'
        },
        KonaOperator: {
        title: 'Kona Operator 27,5"',
        description: '200mm joustolla varustettu täysverinen alamäkipyörä. Tällä saat parhaan kokemuksen parkin radoilla.'
        },
        Kona13424: {
        title: 'Kona Stinky 24"',
        description: 'Täysjousitettu junnujen maasturi. Sopii kaikentasoisille ja kaikenlaiseen ajoon.'
        },
        FoxTitanJacket: {
        title: 'Ylävartalosuoja Fox',
        description: 'Ylävartalosuoja hissipyöräilyyn.'
        },
        KonaFloralJersey: {
        title: 'Ajopaita',
        description: 'Tyylikäs ajopaita.'
        },
        BellFullFace: {
        title: 'Full Face kypärä',
        description: 'Kypärä hissipyöräilyyn.'
        },
        EnduraHummvee: {
        title: 'Avokypärä',
        description: 'Kypärä maastopoluille.'
        },
        EnduraKnee: {
        title: 'Junior Endura Polvisuojat',
        description: 'Polvisuojat juniorille.'
        },
        EnduraKneeHard: {
        title: 'Endura MT500 Polvisuojat',
        description: 'Aikuisten polvisuojat.'
        },
        EnduraElbow: {
        title: 'Junior Endura kyynärsuojat',
        description: 'Kyynärsuojat juniorille.'
        },
        EnduraGloves: {
        title: 'Ajohanskat',
        description: 'Ajohanskat parkkiin tai poluille.'
        },
        GearSet: {
        title: 'Suojavaruste -setti',
        description: 'Full-face kypärä laseilla, ylävartalosuoja, polvisuojat, ajopaita ja ajohanskat.'
        }
      },
      Services: {
      title: 'Palvelut (Ei käytössä)',
      price: 'Hinta',
      button: 'Lisää koriin',
      notForSale: 'Ei saatavilla',
        giftCard1: {
        title: 'Lahjakortti 1',
        description: '25 Euron arvoinen lahjakortti.'
        },
        giftCard2: {
        title: 'Lahjakortti 2',
        description: '50 Euron arvoinen lahjakortti.'
        },
        giftCard3: {
        title: 'Lahjakortti 3',
        description: '100 Euron arvoinen lahjakortti.'
        }
      },
      Trainings: {
      title: 'Valmennukset (Varaukset sähköpostitse)',
      price: 'Hinta',
      button: 'Osta lippu',
      notForSale: 'Ei saatavilla',
        Aloittelija: {
        title: 'Hissipyöräilyn Perusteet',
        description: 'Ryhmä on aloittelioille, kokemusta ei tarvitse olla. Full face kypärä, sekä polvisuojat pakolliset. Suositeltavaa myös selkäpanssari. Pyörässä tulee olla väh. 130mm joustoa. Jokainen valmennukseen osallistuva huolehtii itse siitä, että hänellä on suojavarusteet kunnossa. Vuokraamosta saat paikattua puutteita koulutusta varten. Varaathan omasi ajoissa! Valmennuksen hintaan ei sisälly varusteita tai hissilippua. Tapahtumaan ilmoittautuneita ei ole vakuutettu tapahtumajärjestäjän toimesta, huolehdithan siis tarvitsemastasi vakuutusturvasta itse. Osallistumismaksua ei palauteta vaikka henkilö on estynyt. Osallistumisoikeuden siirtämisestä on ilmoitettava viimeistään 48 tuntia ennen tapahtumaa.'
        },
        Aloittelija2: {
        title: 'Hissipyöräilyn Perusteet',
        description: 'Ryhmä on aloittelioille, kokemusta ei tarvitse olla. Full face kypärä, sekä polvisuojat pakolliset. Suositeltavaa myös selkäpanssari. Pyörässä tulee olla väh. 130mm joustoa. Jokainen valmennukseen osallistuva huolehtii itse siitä, että hänellä on suojavarusteet kunnossa. Vuokraamosta saat paikattua puutteita koulutusta varten. Varaathan omasi ajoissa! Valmennuksen hintaan ei sisälly varusteita tai hissilippua. Tapahtumaan ilmoittautuneita ei ole vakuutettu tapahtumajärjestäjän toimesta, huolehdithan siis tarvitsemastasi vakuutusturvasta itse. Osallistumismaksua ei palauteta vaikka henkilö on estynyt. Osallistumisoikeuden siirtämisestä on ilmoitettava viimeistään 48 tuntia ennen tapahtumaa.'
        },
        Kokenut: {
        title: 'Hissipyöräily Kokeneemmille',
        description: 'Ota ajotaitojen kehittämiselle selvä suunta. Ryhmässä käydään läpi mm. hyppyjä ja droppeja. Hissinkäyttö pitää olla hallussa. Full face kypärä, sekä polvisuojat pakolliset. Suositeltavaa myös selkäpanssari. Pyörässä tulee olla väh. 150mm joustoa. Jokainen valmennukseen osallistuva huolehtii itse siitä, että hänellä on suojavarusteet kunnossa. Vuokraamosta saat paikattua puutteita koulutusta varten. Varaathan omasi ajoissa! Valmennuksen hintaan ei sisälly varusteita tai hissilippua. Tapahtumaan ilmoittautuneita ei ole vakuutettu tapahtumajärjestäjän toimesta, huolehdithan siis tarvitsemastasi vakuutusturvasta itse. Osallistumismaksua ei palauteta vaikka henkilö on estynyt. Osallistumisoikeuden siirtämisestä on ilmoitettava viimeistään 48 tuntia ennen tapahtumaa.'
        },
        Kokenut2: {
        title: 'Hissipyöräily Kokeneemmille',
        description: 'Ota ajotaitojen kehittämiselle selvä suunta. Ryhmässä käydään läpi mm. hyppyjä ja droppeja. Hissinkäyttö pitää olla hallussa. Full face kypärä, sekä polvisuojat pakolliset. Suositeltavaa myös selkäpanssari. Pyörässä tulee olla väh. 150mm joustoa. Jokainen valmennukseen osallistuva huolehtii itse siitä, että hänellä on suojavarusteet kunnossa. Vuokraamosta saat paikattua puutteita koulutusta varten. Varaathan omasi ajoissa! Valmennuksen hintaan ei sisälly varusteita tai hissilippua. Tapahtumaan ilmoittautuneita ei ole vakuutettu tapahtumajärjestäjän toimesta, huolehdithan siis tarvitsemastasi vakuutusturvasta itse. Osallistumismaksua ei palauteta vaikka henkilö on estynyt. Osallistumisoikeuden siirtämisestä on ilmoitettava viimeistään 48 tuntia ennen tapahtumaa.'
        },
        Juniori: {
        title: 'Hissipyöräilyn Perusteet Junioreille (Ikä 8-13)',
        description: 'Bike Park Instructor Elise Kulmala vetää valmennuksen, jossa käydään läpi hissipyöräilyn perusteita. Ryhmä on aloitteleville junioreille suunnattu valmennus. Kokemusta ei tarvitse olla. Full face kypärä, sekä polvisuojat pakolliset. Suositeltavaa myös selkäpanssari. Enduro- tai alamäkipyörä. Jokainen valmennukseen osallistuva huolehtii itse siitä, että hänellä on lajin edellyttämä varustus kunnossa. Meri-Teijo Bike Parkin vuokraamosta saa varattua varusteita ja pyöriä tarpeen mukaan. Varaa omasi ajoissa! Tapahtumaan ilmoittautuneita ei ole vakuutettu tapahtumajärjestäjän toimesta, huolehdithan siis tarvitsemastasi vakuutusturvasta itse. Osallistumismaksua ei palauteta vaikka henkilö on estynyt. Osallistumisoikeuden siirtämisestä on ilmoitettava viimeistään 48 tuntia ennen tapahtumaa.'
        }
      }
    }
  },

  contact: {
  navbartitle: 'Yhteystiedot',
  title: 'Yhteystiedot',
    mapmarkers: {
    transitionentrance: 'Sisäänkäynnin siirtymä',
    transitiontop: 'Siirtymä huippu',
    transitionlift: 'Siirtymä hissille',
    lift: 'Ankkurihissi',
    welcoming: 'LÖYDÄT MEIDÄT TÄÄLTÄ!',
    parking: 'Parkkipaikka',
    reception: 'Vuokraamo & Kahvio, Vessa, Työkaluja',
    liftstation: 'Hissiasema, Työkaluja',
    entrance: 'Pääsisäänkäynti alueelle',
    fireplace: 'Tulentekopaikka',
    toilet: 'Vessa',
    wash: 'Pyörän pesupaikka',
    tools: 'Työkaluja',
    endal: 'Endalin laavu',
    teerisaari: 'Teerisaaren laavu',
    kalasuntti: 'Kalasuntin laavu',
    vicksback: 'Vicksbäckin laavu'
    },
    box: {
      location: {
      title: 'Sijainti',
      description: 'Teijontie 345, 25570 Teijo'
      },
      phone: {
      title: 'Puhelimitse',
      valtteri: 'Valtteri Vainio - 041 313 3051',
      jani: 'Jani Salminen - 041 314 7147'
      },
      email: {
      title: 'Sähköposti',
      description: 'asiakaspalvelu@meriteijobikepark.com'
      }
    }
  },

  trails: {
  navbartitle: 'Reittikartta',
  ogdh: 'High speed downhill trail with drops and gap jumps running down the steepest parts of MTBP.',
  hanger: 'High speed freeride trail with unique sketchy features and challenging jumps. Coming 2022 August.',
  valhalla: 'This gem requires some pedaling. Once you get the job done, you will be rewarded with amazing scenery and unique natural features.',
  dipnrip: 'Very rocky trail full of demanding and tight sections. Watch out, make a small mistake and youre out!',
  pipeline: 'Technical downhill trail with a little bit of everything, minus the gap jumps! Steep stuff, rocky sections and speedy descends.',
  hybrid: 'Flow"ish" mixed trail with everything from machine built flow features to rocky tech sections and challenging jumps. Fun fact: First feature ever built in MTBP was built on the Hybrid track. It was also the first trail with a name.',
  otb: 'Machine & hand built Flow trail zig-zaging through beautiful forest scenery. Black freeride-line variant starts from the middle section.',
  jcb: 'Opening in June or August 2022. Freeride track.',
  srock: 'Steep & techy, mostly singletrack trail with beautiful natural rock formations and features throughout.',
  vacation: 'Almost a kilometer long singletrack through the woods and around a pond. Some handbuilt sections to increase flow, but mostly natural. Trail was made easier for 2021 with beginners in mind.',
  safari: 'Machine built flow trail with unique wooden features, table top jumps and easy drops. Ideal for beginners.',
  snake: 'Machine built wide flow trail with big berms and easy pumps. Suitable for beginners.',
  jordie: 'Full of unique hand built features. Big FMX Ramp jump is a new feature for Jordie in 2021. Along with many other smaller features and upgrades.',
    classes: {
    title: 'Vaikeusasteet',
      green: {
      title: 'Erittäin Helppo',
      description: 'Ei yllätyksiä. Ensikertalaisille suunnattu rata.'
      },
      blue: {
      title: 'Helppo',
      description: 'Matalan kynnyksen elementtejä. Kaiken voi ajaa renkaat maassa.'
      },
      red: {
      title: 'Keskivaikea',
      description: 'Sisältää vaikeita elementtejä. Vaikeissa paikoissa kiertomahdollisuus.'
      },
      black: {
      title: 'Vaikea',
      description: 'Vaativa rata, jossa vauhtia vaaditaan. Yleensä ei kiertomahdollisuuksia. Tutustu huolella ratoihin ennen ajamista.'
      },
      hardcore: {
      title: 'HC',
      description: 'Pro-rata, vain ammattilaisille.'
      }
    } 
  },

  openingtimes: {
  navbartitle: 'Aukioloajat',
  title: 'Viides kausi on päättynyt.',
    monday: {
    title: 'Maanantai - ',
    status: 'Suljettu',
    },
    tuesday: {
    title: 'Tiistai - ',
    status: 'Suljettu',
    },
    wednesday: {
    title: 'Keskiviikko - ',
    status: 'Suljettu',
    },
    thursday: {
    title: 'Torstai - ',
    status: 'Suljettu',
    },
    friday: {
    title: 'Perjantai - ',
    status: 'Suljettu',
    },
    saturday: {
    title: 'Lauantai - ',
    status: 'Suljettu',
    },
    sunday: {
    title: 'Sunnuntai - ',
    status: 'Suljettu'
    }
  },

  events: {
  navbartitle: 'Tapahtumat',
  title: 'Tapahtumat',
  description: 'Klikkaa tapahtumaa avataksesi lisätietoja!',
    event1: {
    title: 'Soft Openings',
    secondtitle: '18.5. & 25.5.2024',
    link: '',
    description: 'Hissit pyörivät klo 10-16. Pyrimme avaamaan mahdollisimman monta rataa näille ajopäiville. Tällä hetkellä varmasti auki olevia ratoja: Safari, OTB, Hanger, Patteri, Hybrid, Pipeline, OG DH, Jordie, Vacation, Dip n Rip, Valhalla, S-Rock, Rölli. Lipunmyynti sijaitsee ala-asemalla, hissien yhteydessä. Kausikortti voimassa vasta virallisissa avajaisissa. Hissilippu päiväksi 25€. Parkkitilaa on myös ala-aseman läheisyydessä. Vuokraamo ja kioski ovat suljettu.',
    },
    event2: {
    title: 'MTBP 2024 Avajaiset',
    secondtitle: '1.6.2024',
    link: '',
    description: 'Kausi käynnistyy kesäkuun ekana lauantaina 1.6. Radoille on luvassa paljon mielekkäitä päivityksiä ja uutta ajettavaa. Hissit pyörivät La & Su 10-17. Tervetuloa avaamaan kausi kanssamme!'
    },
    event3: {
    title: 'Suomi DH',
    secondtitle: '8.-9.6.2024',
    link: 'LISÄTIETOA TAPAHTUMASTA',
    description: 'Suomi DH:n ensimmäinen osakilpailu ajetaan tänä vuonna Meri-Teijolla. DH-kisa sai kaudelle 2023 aivan uuden radan. Tälle vuodelle jo valmiiksi jännittävälle radalle on tehty lisää parannuksia ja linjavaihtoehtoja. Mielenkiintoisia ja jännittäviä hetkiä siis myöskin kisan seuraajille!'
    },
    event4: {
    title: 'Legit Weekend',
    secondtitle: '11.-12.6.2022 by PussCamp',
    link: 'Pusscamp.com',
    description: 'Valmennusviikonloppu! Valmentajina Elise Kulmala, Petteri Leivo, Juuso Mäenpää, Leo Kokkonen sekä Jasmin Kansikas. Ryhmistä lisää PussCamp verkkosivuilla, linkki alla. Lippuja rajoitettu määrä. Näytteilleasettelijoina mm. Trek, Canyon, Pole, Diamond Bikes & Liv Bikes.'
    },
    event5: {
    title: 'Legit Weekend - Valmennusviikonloppu',
    secondtitle: '21.-22.8.2021',
    link: 'Pusscamp',
    description: 'Lauantaille tällä hetkellä varattu kolme kurssia -> Hissipyöräilyn perusteet klo 10:00-12:00, kokeneemmille klo 12:30-14:30, sekä hypyt, dropit & mutka-ajo klo 15-18 kurssi. Valmentajana itse Elise Kulmala! Liput ja lisätietoja Puss Campin verkkokaupasta:'
    },
    event6: {
    title: 'Arkipäivien Vuokraedut - Joka arkipäivä!',
    secondtitle: '6.6.2024 alkaen.',
    description: 'Arkisin on voimassa pakettitarjous. Pakettiin kuuluu vuokrapyörä, bike park lippu ja täysi suojavarustesetti. Hinnoittelu on seuraava: 50€/2h, 65€/3h ja 75€/4h. Suosittelemme varaamaan vuokrakalustoa etukäteen sähköpostitse. Ilmoitathan tulijoiden määrän, kuljettajien pituuden ja painon, sekä kokemustason (ensikertalainen, aloittelija, kokenut, harrastaja).'
    },
    event7: {
    title: '2022 Kausikorttien ennakkomyynti alkaa',
    secondtitle: '6.8.2021',
    description: '2022 Kausikortit alennettuun hintaan 159€ (norm. 289€). Tämän halvemmalla ei ensi kauden kausikortti irtoa. Varmista paikkasi tulevan kauden tohinoihin edullisesti!'
    },
    event8: {
    title: 'Ruska Race 2021',
    secondtitle: '24.10.2021',
    description: 'Kilpailussa ajetaan kolme(3) eri erikoiskoetta 2-4 kertaa, joista kaksi nopeinta aikaa per erikoiskoe otetaan huomioon ajanotossa. Kilpailun osanottajamäärä on rajattu 48 kilpailijaan. Lisätietoja ja ilmoittautuminen linkistä',
    link: 'Lisätietoja ja ilmoittautumiseen tästä'
    },
    event9: {
    title: 'Kauden päätös 2021',
    secondtitle: '30.-31.10.',
    description: 'Kauden päättäjäis viikonloppuna ajetaan normaalien aukioloaikojen mukaan. Yhdessäoloa ja ajoa kauden viimeisenä viikonloppuna! Tervetuloa!',
    link: 'Facebook'
    },
    event10: {
    title: 'Ansa ja Kuoppa Freeride Sessions',
    secondtitle: '6.7.2024',
    description: 'Freeride Sessions palaa jälleen Teijoon. Tapahtumassa ajetaan rennolla aikataululla ja huipentuu junasessioihin. Parhaita vetoja ja vetoasennetta palkitaan edelleen ja illalla rillaillaan! Kuvaajana toimii, kuten aiempinakin vuosina, Tomi Tähti.',
    link: ''
    },
    event11: {
    title: 'MTBRally Gravity Cup 23 - Etelä 1/3',
    secondtitle: '11.6.2023',
    description: 'Gravity Cupin ensimmäinen osakilpailu ajetaan Meri-Teijolla. Ilmoittautuminen kilpailuun tapahtuu register.mtbrally.com sovelluksen kautta. Osallistumismaksut ovat porrastettu lapsista aikuisten luokkiin. Maksutavat ovat verkkopankit sekä mobilepay. Lisätietoja tapahtumasta alla olevasta linkistä.',
    link: 'MTBRally Kotisivut'
    },
    event12: {
    title: 'MTB Rally tapahtumaviikonloppu',
    secondtitle: '14.-15.9.2024',
    description: 'Kesällä 2024 toteutamme viisi kisaviikonloppua. Kausi käynnistyy Fiskars Village Bike Expossa, jossa keskitytään perinteiseen MTB-enduroon. Pääsarjat starttaavat kesäkuussa! Isoja uusia juttuja ovat Slopestyle-kisasarja sekä kaksipäiväinen alamäkileiri Iso-Syötteellä!',
    link: 'MTBRally Kotisivut'
    },
    event13: {
    title: 'Mimmipäivä - Bike Park tutuksi',
    secondtitle: '19.8.2023',
    link: 'VERKKOKAUPPA',
    description: 'Tutustumispäivä Bike Parkiin yhdessä Liisa Laineen (@liisa_laine) ja Minilla Tikkakosken (@mimmimaessa) kanssa. Päivän tarkoituksena on tutustua Bike Parkin tarjontaan ja verkostoitua. Siispä itsensä ylittämistä ja hauskaa yhdessä! Tapahtuma ei ole vain naisille. Päivän etuja: Päivälipun hinta vain 25€ (norm. 33e) / Vuokrapaketit sis. hissilippu, vuokrapyörä ja suojavarustesetti 50€/2h, 65€/3h & 75€/4h. Ostokset voi hoitaa suoraan meidän verkkokaupan kautta ->'
    }
  },
  
  partners: {
  navbartitle: 'Kumppanit',
  meriteijoski: 'Teijo Ski & Action',
  title: 'Kumppanit',
    partner1: {
    title: 'Bikeshop Turku',
    secondtitle: 'Pyöräilyn erikoisliike Turussa',
    link: 'KOTISIVUT',
    description: 'Bikeshop.fi on Suomen monipuolisin, nopein ja luotettavin pyöräilyn verkkokauppa. Bikeshop.fi on perustettu vuonna 2009, mutta taustalta löytyy perinteisempää pyöräkauppaa kahdeksalta vuosikymmeneltä Turussa. Bikeshop.fi on siis yhtä kuin Ajopyörä, Turku ja Ajopyörä, Turku = Bikeshop.fi'
    },
    partner2: {
    title: 'HHCMB',
    secondtitle: 'Maastopyöräilyyn erikoistunut seura',
    link: 'SEURAN SIVUT',
    description: 'Habanero Mountainbikers on salolais-turkulainen vuonna 2003 perustettu maastopyöräilyyn erikoistunut pyöräilyseura. Tuemme ja edistämme kaikkia pyöräilyn alalajeja. Seurassamme suosituimmat lajit ovat Marathon (XCM), enduro, alamäkiajo (DH), Cross-Country (XCO) sekä maantiepyöräily.'
    },
    partner3: {
    title: 'Teijo Ski & Action',
    secondtitle: 'Laskettelu- ja aktiviteettikeskus',
    link: 'SKI:N KOTISIVUT',
    description: 'Bike Parkin lisäksi Teijo Ski & Action tarjoaa palveluita vuoden ympäri. Talvisin bike park muuttuu laskettelukeskukseksi. Teijon ja Mathildedalin alueen idylliset ruukkikylät ja Kansallispuiston sylissä sijaitseva laskettelukeskus kutsuu! Tunti Turusta, puolikas Salosta, Helsingistäkin alle kahden tunnin ajomatkan päässä.'
    },
    partner4: {
    title: 'Fillaritalli',
    secondtitle: 'Pyörähuolto, siellä missä sinäkin.',
    link: 'TALLIN KOTISIVUT',
    description: 'Fillaritalli on Turun alueen helpoin ja kätevin pyörähuolto. Meillä korjataan ja huolletaan fillarit aina edulliseen hintaan - vuosien ammattitaidolla!'
    },
    partner5: {
    title: 'Puss Camp',
    secondtitle: 'Valmennuksia ja Naisten maastopyöräleiri',
    link: 'CAMPIN KOTISIVUT',
    description: 'Puss Camp tarjoaa erilaisia valmennuksia ja tapahtumia pyöräilyn parissa. Tarjoamme Bikepark -valmennusta ryhmille. Toiveiden mukaan voimme toteuttaa erilaisia paketteja kuten valmennusta harrastajille, tutustumista bike park ajoon hissiavusteisesti, sekä myös ajo-opetusta esimerkiksi yrityksille, syntymäpäiviin ja polttariporukoille. Vuoden päätapahtuma on täysi-ikäisille naisille tarkoitettu enduro-, xc-, e-bike ja dh-pyöräilyleiri.'
    }
  },

  info: {
  navbartitle: 'Info',
  title: 'Mikä MTBP? Mitä on alamäkipyöräily? Kuinka aloitan?',
  title2: 'Onko termistö hukassa? Look no further!',
    mtbp: {
    title: 'Meri-Teijo Bike Park',
    description: 'MTBP eli Meri-Teijo Bike Park on harrastelähtöinen, Teijon kansallispuiston kainalossa sijaitseva alamäki- ja maastopyöräilyyn erikoistunut pyöräilykeskus. Alamäkipyöräilyllä tarkoitetaan pyöräilyä, jossa ylämäet selätetään laskettelusta tutuilla hisseillä. Ulkomailla harrastus on jo todella suureksi kasvanut, mm. Ruotsissa on valtavia kävijämääriä kesäisin hiihtokeskuksissa. Rohkaisemme ihmisiä kokeilemaan lajia laskemalla rimaa mahdollisimman alas. Rakennamme ja kehitämme helppoja ratoja jatkuvasti, jotta vasta aloittelevatkin voivat nauttia.',
    description2: 'Maastopyöräilyä on hyvä harrastaa myös lähialueen poluilla. Teijon kansallispuisto on hieno paikka tutkia vuokraamon sähköfatbikeillä. Hyvillä fillareilla kokemuksesta voi tulla elämys! Oppaamme tai navigaattorit voivat ohjata teidät kansallispuiston parhaille poluille, ja reiteille pääseekin suoraan keskuksemme lähistöltä. Teemme myös räätälöityjä aktiviteettipaketteja ja yksityisvalmennuksia yrityksille, polttareihin tai muille ryhmille! Teemme yhteistyötä Teijo Ski & Actionin kanssa, joten voimme halutessasi yhdistää vaikka mäkiautoilun ja alamäkipyöräilyn. Otathan yhteyttä asiakaspalvelu@meriteijobikepark.com!'
    },
    liikuntaetu: {
    title: 'Liikuntaeduista',
    description: 'Meillä on käytössä Smartum, Edenred & ePassi. Käyttö vain sovelluksella. Voit maksaa kaikkia tuotteitamme liikuntaeduilla. Riittää, että siirrät vain tuotteen/tuotteiden summan sovelluksessa palveluntarjoajalle: Meri-Teijo Bike Park. Varmista etukäteen puhelimitse tai sähköpostitse, että tuotetta on vielä saatavilla.'
    },
    teijo: {
    title: 'Historiallisia Ruukkikyliä ja Suomalainen Saaristo',
    description: 'Teijon ruukkikylät alueella toimi aikanaan kolme ruukkia: Mathildedal, Teijo ja Kirjakkala, jotka sijaitsevat vain muutaman kilometrin etäisyydeltä toisistaan meren rannalla. Kirjakkalan ja Teijon ruukit perustettiin 1686 ja Mathildedalin ruukki 1852. Yhdessä ne muodostavat kulttuurihistoriallisen nähtävyyskokonaisuuden, joka kuvaa hyvin suomalaisen raudanvalmistuksen eri vaiheita sekä ruukkiyhteisöjen rakennetta työväen ja patruunoiden asuntoineen. Pääosa rakennuksista on yksityisessä omistuksessa, mutta osassa toimii myös erilaisia matkailu- ja kulttuuripalveluja tarjoavia sekä teollista toimintaa harjoittavia yrityksiä.'
    },
    teijonkansallispuisto: {
    title: 'Teijon Kansallispuisto',
    description: 'Teijon kansallispuisto on Suomen kansallispuistoperheen uusin tulokas, upea salomaa keskellä varsinaissuomalaista kulttuurimaisemaa. Eteläsuomalaisen luonnon lähes kaikki elementit ovat edustettuina Teijossa: lyhyenkin retken aikana voi kulkea jylhässä kalliomaastossa, ihailla kaunista järvimaisemaa, kokea suon ja metsän mosaiikin sekä aistia meren tunnun.',
    link: '',
    },
    food: {
    title: 'Ruokailu ja Majoitus',
    description: 'Kesäkaudella 2023 pidämme auki kioskia. Kioskista saa pieneen nälkään ja janoon naposteltavaa, hedelmiä, kuivamuonaa ja einesruokaa. Suuremman nälän koittaessa lähialueen ravintolat palvelevat, joista saa kesäaikaan maistuvaa ruokaa. Vain 3km automatkan päässä sijaitsevat Teijon ja Mathildedalin ruukkikylät, joista löydät tarpeesi mukaiset palvelut ja voit jäädä vaikka viikonlopuksi. Lisätietoja -> ',
    link: 'Visit Mathildedal',
    visitmathildedal: {
      title: 'Mathildedal',
      description: 'Hyväntuulisessa ja eläväisessä kylässä järjestetään konsertteja, keikkoja, taidenäyttelyitä, teatteria, tapahtumia ja monenlaisia juhlia läpi vuoden. 1800-luvun ruukkimiljöö kätkee sisälleen modernin kyläyhteisön, jonka 130 asukkaasta moni on yrittäjiä. Vanhat tehdasrakennukset, sydämellinen palvelu ja vehreä ympäristö tarjoavat elämyksiä, rauhaa ja romuromanttista kauneutta kaikille aisteille!',
      link: 'Kurkkaa kylän kotisivut tästä',
      }
    },
    vakuutukset: {
    title: 'Kuinka aloittaa harrastus? Miten hissiä käytetään? Entä vakuutukset?',
    description: 'Hissinä toimii laskettelusta tutut ankkurihissit ja lasten bike parkilla mattohissi. Hisseihin mennään porttien läpi, joista pääsee ladatulla Snowcard-kortilla. Neuvomme kokeilemaan hissiin menoa pyörän satulalla istuen siten, että ankkurikapula tulee satulaputken taakse. Näitä perusasioita henkilökuntamme opastaa vuokraamolla ja hissiasemalla. Mikäli tahdot perusteellisempaa perehdytystä, tai ajo-opetusta, niin ota yhteyttä. Tapahtumat välilehdeltä näkee myös valmennusviikonloppujen ajankohtia. Aloittelijalle suosittelemme hissipyöräilyn perusteet- kursseja, joissa hissipyöräilyn aloitus otetaan haltuun turvallisesti. Tämänkaltaiset valmennukset valmistavat sinut itsenäiseen Bike Park ajoon. Bike Parkin radoilla ajo on loppuviimein omalla vastuulla. Näin vauhdikkaassa lajissa on suojavarusteista huolimatta riski loukkaantua, joten suosittelemme vakuutusten hankkimista. Noudatamme SHKY:n sopimusehtoja. \n Tutustuthan niihin lisää. Pääset tsekkaamaan tästä linkistä -> ',
    link: 'Ski.fi'
    },
    dh: {
    title: 'DH - Downhill',
    description: 'Deehoossa eli alamäkipyöräilyssä ajetaan kilpailutilanteessa aikaa vastaan. Merkitty rata tulee ajaa mahdollisimman nopeasti alas. Alamäkipyöräily on maastopyöräilyn alalaji, jossa ajetaan alamäkipainotteisia ratoja, joita Bike Parkit tarjoavat. Luonnostakin toki löytyy alamäkipätkiä, mutta bike parkissa rajojaan voi kokeilla hieman mukavammin. Voit varustautua kunnon suojavarustein, kun hissi hoitaa sinut ylös!'
    },
    enduro: {
    title: 'Enska - Enduro',
    description: 'Enduro on monipuolisin maastopyöräilyn alalaji, sillä siinä yhdistyvät alamäkiajon vauhti ja teknisesti vaikeat laskut, sekä cross-countryssä tarvittava kunto tasamaastossa ja nousu osuuksilla. Lajin monipuolisuus on varmasti merkittävin syy  enduron nousevan suosion taustalla. Enduropyörät ovat lisäksi niin monipuolisia, sillä niiden potentiaali on kasvanut alamäkiajossakin, polkutehokkuutta unohtamatta.'
    },
    xc: {
    title: 'XC - Cross Country',
    description: 'Cross Country on perinteisin maastopyöräilyn alalaji. Maastopyörällä ajetaan luonnossa poluilla (singletrack). XC-kilpailuissa ajetaan usean tunnin ajan radalla, joka asettaa sekä ajajan aerobisen kunnon että ajotekniset kyvyt koetukselle. Maastoajoa voisi verrata murtomaahiihtoon. Kisaan lähtijät lähtevät matkaan samanaikaisesti, eikä ulkopuolista apua saa käyttää huoltotoimenpiteissä. Lajiin tarkoitetut pyörät ovat mahdollisimman kevyitä ja ajoasennoltaan usean tunnin satulassa hikoilemisen mahdollistavia'
    },
    hissipyora: {
    title: 'Lift assisted mountain biking',
    description: 'Lift assisted mountain biking is quite a new thing in Finland. Our goal is to create a fun activity where the whole family can join. Just like skiing in winter! Starting mountain biking is not so hard as people tend to think, especially in bike parks who put work in beginner tracks and elements. You use the same lifts as in skiing to get to the top. Meaning of lift is simple: you get to enjoy downhill riding and get maximum reps during the day without pedaling/pushing up. Because if you love downhill you dont want to pedal up!'
    },
    otb: {
    title: 'OTB - Over the bars',
    description: 'Joskus maastopyöräilijät lentävät ohjaustankonsa yli törmätessään eturengas edellä pysäyttävään esteeseen.'
    },
    berm: {
    title: 'Bermi',
    description: 'Bermi on kallistettu mutka, joka on yleensä tehty maa-aineesta tai puusta.'
    },
    bunnyhop: {
    title: 'Bunny hop',
    description: 'Käytetään mm. esteen ylitykseen. Hypätään pyörällä ilmaan siten, että eturengas nousee ensin maasta, sitten takarengas.',
    },
    drop: {
    title: 'Pudotus eli Droppi',
    description: 'Radalla tai polulla voi olla kalliokieleke, josta täytyy pudottautua jatkaakseen.'
    },
    hardtail: {
    title: 'Kovaperä eli hardtail',
    description: 'Kovaperä on pyörä, jossa ei ole takajousitusta lainkaan. Jäykkäperä tarkoittaa samaa asiaa.'
    },
    litkutus: {
    title: 'Litkutus',
    description: 'Litkutukseksi kutsutaan tubeless renkaan tiivistämistä vanteelle.'
    },
    singletrack: {
    title: 'Singletrack',
    description: 'Pyörän levyinen kapea metsäpolku.'
    },
    sinkula: {
    title: 'Sinkula',
    description: 'Yksivaihteinen pyörä.'
    },
    tubeless: {
    title: 'Tubeless',
    description: 'Sisäkumiton rengas, joka tiivistetään vanteelle litkuttamalla. Katso "Litkutus"'
    },
    snakebite: {
    title: 'Snakebite',
    description: 'Renkaan kaksi vierekkäistä reikää, jotka ovat syntyneet kun rengas on joutunut kaksinkerroin vanteen ja terävän kulman väliin.'
    },
    tyomatkatempo: {
    title: 'Työmatkatempo',
    description: '”Leikkimielinen” työmatkahaaste jossa pyritään ohi muista työmatkapyöräilijöistä tai pahimmassa tapauksessa tullaan ohitetuksi.'
    },
    taysjousto: {
    title: 'Täysjousto',
    description: 'Maastopyörä jossa sekä etu-, että takajousitus.'
    },
    taysjaykka: {
    title: 'Täysjäykkä',
    description: 'Maastopyörä jossa ei jousitusta kummassakaan päässä.'
    },
    vapaaratas: {
    title: 'Vapaaratas',
    description: 'Napaan kuuluva rakenneosa, joka päästää takapyörän pyörimään silloinkin, kun polkimet ovat paikoillaan.'
    },
    stemmi: {
    title: 'Stemmi',
    description: 'Ohjaustangon kannatin. Ohjainkannatin.'
    },
    rihtaus: {
    title: 'Rihtaus',
    description: 'Kiekon oikaisu pinnoja kiristämällä.'
    },
    sukkikset:{
    title: 'Sukkikset',
    description: 'Tiukat trikooajohousut.'
    },
    mamil:{
    title: 'MAMIL',
    description: 'Middle Aged Men In Lycra. (Keski-ikäiset miehet trikoissa)'
    }
  },

  basket: {
  title: 'Ostoskori',
  basketIcon: 'Ostoskori',
  cashIcon: 'Kassa',
    basketcontent: {
    title: 'Tuotteet',
    continueshoppingbutton: 'Jatka Ostoksia',
    product: 'Tuote',
    price: 'Hinta',
    count: 'Määrä',
    total: 'Yhteensä',
    value: '0 Eur',
    cashoutbutton: 'Siirry maksamaan >',
    emptyBasket: 'Ostoskorisi on tyhjä'
    },
    basketform: {
    title: 'Kassa',
    subTitle: 'Asiakastiedot',
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    email: 'Sähköposti',
    phone: 'Puhelin',
    country: 'Maa',
    countrySelect: 'VALITSE MAA',
    address: 'Osoite',
    postalCode: 'Postinumero',
    postOffice: 'Postitoimipaikka',
    deliveryTerms: 'Ostaaksesi hyväksy verkkokauppamme',
    terms: 'Toimitusehdot',
    confirmButton: 'Vahvista Tilaus',
    shopConditions: conditionsText
    },
    basketSuccess: {
    title: 'Kiitos tilauksestasi',
    returnButton: '< Palaa etusivulle',
      customer: {
      title: 'Tilaajan tiedot',
      firstName: 'Etunimi',
      surName: 'Sukunimi',
      date: 'Päivämäärä',
      paymentMethod: 'Maksutapa'
      },
      order: {
      title: 'Tilauksen tiedot',
      id: 'Tilausnumero',
      product: 'Tuote',
      amount: 'Määrä',
      price: 'Á -Hinta',
      finalprice: 'Loppusumma'
      }
    }
  }
};

export default fi;